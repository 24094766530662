import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import styles from './styles.module.scss';
import logoDark from "../../images/logo-main.png";

import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { login, enterCode } from "../../services";
import Cookies from 'universal-cookie';

export const Auth = () => {
  const [user, setUser] = useRecoilState(userState);
  const [password, setPassword] = useState(null);
  const [error,setError] = useState('');
  const [codePage,setCodePage] = useState(false);
  const [code,setCode] = useState('');
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    const cookies = new Cookies();
    const token = cookies.get('token');

    if(token){
      navigate('/dashboard');
    }
  },[]);

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();

      try{
        const loginResult = await login({email:user,password});

        if(loginResult.data.success){
            setCodePage(true)
        }else{
          setError(loginResult.data.error);
          console.log(loginResult.data);
        }
      }catch (error){
        setError(error.message);
      }




    },
    [user, password, login]
  )

  const sendCode = useCallback(
    async(e) => {
      e.preventDefault();

      try{
        console.log('codeeee')
        console.log(code)
        const loginResult = await enterCode({email:user,code});

        if(loginResult.data.success){
            const token = loginResult.data.token;
            const expiryDate = new Date(new Date().getTime() + (9 * 24 * 3600 * 1000));
            const cookies = new Cookies();
            await cookies.set('token', token, { path: '/', expires:expiryDate });
            navigate('/dashboard');
        }else{
          setError(loginResult.data.error);
          console.log(loginResult.data);
        }
      }catch (error){
        setError(error.message);
      }




    },
    [enterCode, code, user]
  )

  if(codePage){
    return (
      <div className="global-container">
        <div className={styles.loginContainer}>
  
  
          <div className={styles.loginColumn}>
              <div className="global-login-card-view" id={styles.loginPanel}>
                <img src={logoDark} className={styles.loginLogo} />
                <br/><br/>
                We sent a code to your email address. Please enter it to log in.
                <br/><br/>
                <TextInput placeholder={'Enter Code'} type={'password'} onChange={(value)=>{setCode(value)}} />
  
                <div className={styles.submitButtonContainer}>
                  <SubmitButton loading={loading} title={"Enter"} onClick={sendCode} />
                </div>
  
                <div className={styles.loginError}>{error}</div>
                <br/>
                <div className={styles.loginLinks}>
                </div>
              </div>
  
              <div className={styles.loginCopyright}>
                  &copy; Copyright BUCKEYE HILLS LTD 2022
              </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className="global-container">
      <div className={styles.loginContainer}>


        <div className={styles.loginColumn}>
            <div className="global-login-card-view" id={styles.loginPanel}>
              <img src={logoDark} className={styles.loginLogo} />
              <br/>
              <TextInput placeholder={'Email'} type={'email'} onChange={(value)=>{setUser(value)}} />
              <TextInput placeholder={'Password'} type={'password'} onChange={(value)=>{setPassword(value)}} />

              <div className={styles.submitButtonContainer}>
                <SubmitButton loading={loading} title={"Login"} onClick={onSubmit} />
              </div>

              <div className={styles.loginError}>{error}</div>
              <br/>
              <div className={styles.loginLinks}>
              </div>
            </div>

            <div className={styles.loginCopyright}>
              	&copy; Copyright BUCKEYE HILLS LTD 2022
            </div>
        </div>
      </div>
    </div>
  )
}
