import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import TimePicker from "rc-time-picker";
import {sendConfirmationEmails, sendReminderEmails, updateRecord, getMedicsBySearch, getSolicitorsBySearch, resendEmails, getAllRecords,getProfileDiscover, getUserDetailsService, getRecentBookings, getSellerProductDetails,createOrder} from '../../services/';
import styles from './styles.module.scss';

import logoDark from "../../images/logistible-commerce.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import noOrders from '../../images/no-orders-graphic.png';
import plusIcon from '../../images/plus-icon.png';
import removeIcon from '../../images/remove-icon.png';
import xButton from '../../images/x-button.png';

import dashboardWidgetCart from '../../images/dashboard-widget-cart.png';
import dashboardWidgetOrders from '../../images/dashboard-widget-orders-icon.png';
import dashboardWidgetSales from '../../images/dashboard-widget-sales.png';
import dashboardWidgetStore from '../../images/dashboard-widget-store.png';
import dashboardCustomerFeedback from '../../images/dashboard-customer-feedback.png';

import { useSelector } from 'react-redux';
import { setProfile } from '../../redux/authSlice.js';
import store from '../../redux/store.js';

import moment from 'moment';

const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

const STATUS_LIST = [
  'Pending',
  'Confirmed',
  'Cancelled'
]

const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const Referrals = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [error,setError] = useState('');
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [loadingForm,setLoadingForm] = useState(false);
  const [editReferralOpen,setEditReferralOpen] = useState(false);
  const [orderProductsCount, setOrderProductsCount] = useState(0);
  const navigate = useNavigate();


  const [piab, setPiab] = useState('')
  const [claimantName, setClaimantName] = useState('')
  const [claimantPhone, setClaimantPhone] = useState('')
  const [claimantAddress, setClaimantAddress] = useState('')
  const [appointmentTime, setAppointmentTime] = useState('')
  const [claimantEmail, setClaimantEmail] = useState('')
  const [claimantDOB, setClaimantDOB] = useState('')
  const [incidentDate, setIncidentDate] = useState('')
  const [ldta, setLdta] = useState('')
  const [solicitorName, setSolicitorName] = useState('')
  const [solicitorEmail, setSolicitorEmail] = useState('')
  const [specialNoteToSolicitor, setSpecialNoteToSolicitor] = useState('')
  const [specialNoteToDoctor, setSpecialNoteToDoctor] = useState('')
  const [medicType, setMedicType] = useState('')
  const [solicitorReference, setSolicitorReference] = useState('')
  const [backupSolicitorEmail, setBackupSolicitorEmail] = useState('')
  const [clinicLocation, setClinicLocation] = useState('')
  const [clinicAddress, setClinicAddress] = useState('')
  const [expertEmail, setExpertEmail] = useState('')
  const [medicalExpert, setMedicalExpert] = useState('')
  const [medicId, setMedicId] = useState('')
  const [expertType, setExpertType] = useState('')
  const [appointmentDate, setAppointmentDate] = useState('')
  const [recordId, setRecordId] = useState(null)
  const [recordIndex, setRecordIndex] = useState(null)
  const [sendClientEmail, setSendClientEmail] = useState(true)
  const [sendMedicEmail, setSendMedicEmail] = useState(true)
  const [sendPiabEmail, setSendPiabEmail] = useState(true)
  const [sendSolicitorEmail, setSendSolicitorEmail] = useState(true)
  const [oldDocuments, setOldDocuments] = useState([])
  const [files, setFiles] = useState([])
  const [filesUpdated, setFilesUpdated] = useState(true);
  const [dropdownDate, setDropdownDate] = useState('');
  const [dropdownTime, setDropdownTime] = useState('');
  const appointmentDateRef = useRef(null);
  const appointmentTimeRef = useRef(null);
  const doctorRefEdit = useRef(null);
  const solicitorRefEdit = useRef(null);
  const medicEmailRef = useRef(null);
  const medicTypeRef = useRef(null);
  const clinicLocationRef = useRef(null);
  const clinicAddressRef = useRef(null);
  const solicitorPrimaryEmailRef = useRef(null);
  const solicitorSecondaryEmailRef = useRef(null);
  const solicitorReferenceRef = useRef(null);
  const [clinics, setClinics] = useState([]);
  const [doctors, setDoctors] = useState([])
  const [solicitors, setSolicitors] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])
  const [status, setStatus] = useState(0)

  const [records, setRecords] = useState([]);

  const resendReferralEmails = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    await setLoadingForm(true)
    const resendRes = await resendEmails({formId:recordId, sendMedicEmail, sendSolicitorEmail, sendClientEmail, sendPiabEmail},token);
    if(resendRes.data.success){
      alert('Emails Sent');
    }else{
      alert('Error sending emails. Some emails may not have been sent:'+resendRes.data.error);
    }

    await setLoadingForm(false)
  }

  const searchDoctors = async(search, token)=>{
    const doctors = await getMedicsBySearch({token,search});
    if(doctors.data.success){
      setDoctors(doctors.data.medics.map((doctor,doctorIndex)=>{
        return {...doctor}
      }));
    }
  }

  const searchSolicitors = async(search, token)=>{
    if(search.length == 0) return;
    const solicitors = await getSolicitorsBySearch({token,search});
    if(solicitors.data.success){
      setSolicitors(solicitors.data.solicitors.map((solicitor,solicitorIndex)=>{
        return {...solicitor}
      }));
    }
  }

  const getReferralDate = (dateString, time)=>{
    let date = dateString.replaceAll(' ','').replaceAll('.','/').split('/')

    let d = date[0],
      m = parseInt(date[1]) - 1,
      y = date[2];

    let h = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[0],
        min = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[1];

      if(y && y.length == 2) y = '20'+y

    if(!d || !m || !y || !h || !min) return 'error'


    const timeInt = Date.parse(d+' '+SHORT_MONTHS[m]+' '+y+' '+h+':'+min+':00 GMT')

    console.log('TIME: '+timeInt)
    console.log(d+' '+m+' '+' '+y+' '+h+' '+min)
    return timeInt
  }

  const updateAppointment = async()=>{
    //setLoading(true);
    let formData = new FormData();
    for(let index = 0; index < files.length;index++){
      formData.append('file'+(index+1), files[index])
    }

    await setLoadingForm(true)

    formData.append('piab', piab)
    formData.append('claimantName', claimantName)
    formData.append('claimantPhone', claimantPhone)
    formData.append('claimantAddress', claimantAddress)
    formData.append('appointmentTime', appointmentTime)
    formData.append('claimantEmail', claimantEmail)
    formData.append('claimantDOB', claimantDOB)
    formData.append('incidentDate', incidentDate)
    formData.append('ldta', ldta)
    formData.append('solicitorName', solicitorName)
    formData.append('solicitorEmail', solicitorEmail)
    formData.append('specialNoteToSolicitor', specialNoteToSolicitor)
    formData.append('specialNoteToDoctor', specialNoteToDoctor)
    formData.append('medicType', medicType)
    formData.append('solicitorReference', solicitorReference)
    formData.append('backupSolicitorEmail', backupSolicitorEmail)
    formData.append('clinicLocation', clinicLocation)
    formData.append('clinicAddress', clinicAddress)
    formData.append('expertEmail', expertEmail)
    formData.append('medicalExpert', medicalExpert)
    formData.append('expertType', expertType)
    formData.append('recordId',  recordId)
    formData.append('appointmentDate', appointmentDate)
    formData.append('medicId', medicId)
    formData.append('status', status)
    formData.append('oldDocuments', oldDocuments.join(','))

    console.log(recordId);

    const cookies = new Cookies();
    const token = await cookies.get('token');

    const uploadResult = await updateRecord(formData,token);
    if(uploadResult.data.success){
      setFiles([])
      setOldDocuments(uploadResult.data.record.documentURLs.split(','))
      alert('Appointment Saved');

      let recordsTmp = records;
      recordsTmp[recordIndex] = uploadResult.data.record;
      setRecords(recordsTmp)
    }else{
      console.log(uploadResult.data)
      alert("Error saving. Please try again.");
    }
    await setLoadingForm(false)
    setLoading(false);
  }


  const uploadFile = async(event)=>{

    let filesList = files;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setFiles(filesList);
    setFilesUpdated(!filesUpdated);
    console.log(files);
    event.target.value = null;
  }

  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    if(!token){
      navigate('/');
    }else{
      setToken(token);
      console.log(token);
      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }

      const recordDetails = await getAllRecords({token});
      if(recordDetails.data.success){
        setRecords(recordDetails.data.records)
        console.log('RECORDS');
        console.log(recordDetails.data.records)
      }else{
        alert("Error getting your details.");
      }


      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);


  const renderFormButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{updateAppointment();}} />
    )
  }

  const renderResendEmailsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Resend Emails" onClick={(event)=>{resendReferralEmails();}} />
    )
  }


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }



  const formatDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  const downloadCSV = ()=>{
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Date,Name,Address,Phone,Email,Price (€)" + "\r\n";
    results.map((result)=>{return([result.orderDate,result.customerName,result.customerAddress,result.customerPhone,result.customerEmail,result.price])}).forEach((rowArray)=>{
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }


  const renderNewOrder = ()=>{
    if(!editReferralOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setEditReferralOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Edit Referral</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>Edit Case</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="PIAB reference number*" type="form-text" onChange={(value)=>{setPiab(value)}} initialValue={piab}/>
                    <TextInput placeholder="Claimant Name*" type="form-text" onChange={(value)=>{setClaimantName(value)}} initialValue={claimantName}/>
                    <TextInput placeholder="Claimant Phone*" type="form-text" onChange={(value)=>{setClaimantPhone(value)}} initialValue={claimantPhone}/>
                    <TextInput placeholder="Claimant Address*" type="form-text" onChange={(value)=>{setClaimantAddress(value)}} initialValue={claimantAddress}/>
                    <TextInput placeholder="Medic Type Needed*" type="form-text" onChange={(value)=>{setMedicType(value)}} initialValue={medicType}/>
                    <TextInput placeholder="Status" type="dropdown" options={STATUS_LIST} onChange={(value)=>{setStatus(STATUS_LIST.indexOf(value))}} initialValue={STATUS_LIST[status]}/>

                  </div>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Claimant Email*" type="form-text" onChange={(value)=>{setClaimantEmail(value)}} initialValue={claimantEmail}/>
                    <TextInput placeholder="Claimant DOB*" type="form-text" onChange={(value)=>{setClaimantDOB(value)}} initialValue={claimantDOB}/>
                    <TextInput placeholder="Incident Date*" type="form-text" onChange={(value)=>{setIncidentDate(value)}} initialValue={incidentDate}/>
                    <TextInput placeholder="LDTA*" type="form-text" onChange={(value)=>{setLdta(value)}} initialValue={ldta}/>
                    <TextInput placeholder="Special Note to Medic*" type="form-textarea" onChange={(value)=>{setSpecialNoteToDoctor(value)}} initialValue={specialNoteToDoctor}/>
                  </div>
                </div>
                <br/><br/>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Solicitor" ref={solicitorRefEdit} options={solicitors} type="form-search" solicitors={true} onChangeText={(text)=>{searchSolicitors(text, token)}} onChange={async(solicitor)=>{
                      await setSolicitorEmail(solicitor.primaryEmail)
                      await setBackupSolicitorEmail(solicitor.secondaryEmail)
                      await setSolicitorName(solicitor.firmName)
                      //await setSolicitorReference(solicitor.reference || '')
                      await solicitorPrimaryEmailRef.current.setTextboxValue(solicitor.primaryEmail);
                      await solicitorSecondaryEmailRef.current.setTextboxValue(solicitor.secondaryEmail);
                      //await solicitorReferenceRef.current.setTextboxValue(solicitor.reference);
                    }} initialValue={solicitorName}/>
                    <TextInput placeholder="Named Email*" ref={solicitorPrimaryEmailRef} type="form-text" onChange={(value)=>{setSolicitorEmail(value)}} initialValue={solicitorEmail}/>
                    <TextInput placeholder="Special Note to Solicitor*" type="form-textarea" onChange={(value)=>{setSpecialNoteToSolicitor(value)}} initialValue={specialNoteToSolicitor}/>
                    <br/><br/>

                  </div>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Solicitor Reference*" type="form-text" onChange={(value)=>{setSolicitorReference(value)}} initialValue={solicitorReference}/>
                    <TextInput placeholder="Admin Email*" ref={solicitorSecondaryEmailRef} type="form-text" onChange={(value)=>{setBackupSolicitorEmail(value)}} initialValue={backupSolicitorEmail}/>
                  </div>
                </div>

                <div style={{justifyContent:"flex-end", width:"calc(100% - 5vw)", textAlign:"right", paddingTop:"8vh",paddingLeft:"0vw",paddingRight:"5vw"}}>
                  <label for="logoInput">
                    <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Document File Upload" onClick={(event)=>{}} />
                  </label>
                </div>
                <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />
                {
                  oldDocuments.filter((item)=>{ return item != ''}).map((file,fileIndex)=>{
                    return(
                      <div className={styles.filesList}>
                        <a href={file}>Existing File {fileIndex+1}</a>
                        <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                          let filesTmp = oldDocuments;
                          filesTmp.splice(fileIndex,1);
                          await setOldDocuments(filesTmp);
                          setFilesUpdated(!filesUpdated);
                        }} className={styles.removeFileButton} />
                      </div>
                    )
                  })
                }
                {
                  files.map((file,fileIndex)=>{
                    return(
                      <div className={styles.filesList}>
                        {file.name}
                        <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                          let filesTmp = files;
                          filesTmp.splice(fileIndex,1);
                          await setFiles(filesTmp);
                          setFilesUpdated(!filesUpdated);
                        }} className={styles.removeFileButton} />
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>Edit Medical Apt.</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Medical Expert" ref={doctorRefEdit} options={doctors} type="form-search" onChangeText={(text)=>{searchDoctors(text, token)}} onChange={async(expert)=>{
                    await setMedicalExpert(expert.name)
                    await setAvailableTimes(expert.ml_med_dates || [])
                    await setClinics((expert.clinics == "[]")?[]:expert.clinics.split('[****SEP****]'))
                    await setMedicType(expert.specialty)
                    await setExpertEmail(expert.contactEmail)
                    await setClinicAddress(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'')
                    await setClinicLocation(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'')
                    await setMedicId(expert.id)
                    await medicTypeRef.current.setTextboxValue(expert.specialty);
                    setExpertType(expert.specialty)
                    await medicEmailRef.current.setTextboxValue(expert.contactEmail);
                    await setClinicAddress(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'');
                    await setClinicLocation(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'');
                    await clinicAddressRef.current.setTextboxValue(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'')
                  }} initialValue={medicalExpert}/>
                  <TextInput placeholder="Expert Type*" ref={medicTypeRef} type="form-text" onChange={(value)=>{setExpertType(value)}} initialValue={expertType}/>

                  </div>

                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Clinic Location*" type="dropdown" options={clinics} ref={clinicLocationRef} onChange={(value)=>{
                    setClinicLocation(value);
                    setClinicAddress(value);
                    clinicAddressRef.current.setTextboxValue(value)
                  }} initialValue={clinicLocation}/>

                  <TextInput placeholder="Available Dates" options={[...new Set(availableTimes.filter((time)=>{return (time.location == clinicLocation)}).map((time)=>{ return time.date }))]} type="dropdown" solicitors={true} onChange={async(value)=>{
                    setDropdownDate(value)
                    setAppointmentDate(value)
                    appointmentDateRef.current.setTextboxValue(value);
                  }} initialValue={dropdownDate}/>
                  <TextInput ref={appointmentDateRef} placeholder="Appointment Date*" type="form-text" onChange={(value)=>{setAppointmentDate(value)}} initialValue={appointmentDate}/>

                <br/><br/>

                <TextInput placeholder="Available Times" options={availableTimes.filter((time)=>{return (time.location == clinicLocation)}).filter((time)=>{return (time.date == dropdownDate)}).map((time)=>{ return time.time })} type="dropdown" solicitors={true} onChange={async(value)=>{
                  setDropdownTime(value)
                  setAppointmentTime(value)
                  appointmentTimeRef.current.setTextboxValue(value);
                }} initialValue={dropdownTime}/>
                  <br/><br/>

                  <div style={{marginLeft:"0vw"}}>
                    <TextInput placeholder="Appointment Time*" type="form-text" options={TIMES}  onChange={(value)=>{
                      setAppointmentTime(value);
                    }} initialValue={appointmentTime} ref={appointmentTimeRef}/>
                  </div>

                  </div>

                </div><br/><br/>

                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Clinic Address*" ref={clinicAddressRef} type="form-text" onChange={(value)=>{setClinicAddress(value)}} initialValue={clinicAddress}/>

                  </div>

                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Expert Email*" ref={medicEmailRef} type="form-text" onChange={(value)=>{setExpertEmail(value)}} initialValue={expertEmail}/>
                  </div>
                </div>

              </div>
            </div>



          </div>

            <div className={styles.formButtonContainer}>
              {renderFormButton()}<br/><br/>

              <span><br/>
              <input type="checkbox" id="checkbox1" name="checkbox1" checked={sendPiabEmail} onChange={(event)=>{setSendPiabEmail(!sendPiabEmail)}}></input>
              <span style={{paddingRight:"0px"}}> Send Email To PIAB?</span>
              </span><br/>
              <span><br/>
              <input type="checkbox" id="checkbox1" name="checkbox1" checked={sendClientEmail} onChange={(event)=>{setSendClientEmail(!sendClientEmail)}}></input>
              <span style={{paddingRight:"0px"}}> Send Email To Client?</span>
              </span><br/>
              <span><br/>
              <input type="checkbox" id="checkbox1" name="checkbox1" checked={sendMedicEmail} onChange={(event)=>{setSendMedicEmail(!sendMedicEmail)}}></input>
              <span style={{paddingRight:"0px"}}> Send Email To Medic?</span>
              </span><br/>
              <span><br/>
              <input type="checkbox" id="checkbox1" name="checkbox1" checked={sendSolicitorEmail} onChange={(event)=>{setSendSolicitorEmail(!sendSolicitorEmail)}}></input>
              <span style={{paddingRight:"0px"}}> Send Email To Solicitor?</span>
              </span>
              <br/><br/>

              {renderResendEmailsButton()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(token){
    return(
      <div className="global-container">
        {renderNewOrder()}
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Referrals"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.NAME+" "+user.SURNAME || "Aalto User"}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"10px", marginRight:"10px", height:"88vh", display:"flex",flexDirection:"column", overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                  View Referrals
                  <div className={styles.searchContainer}>
                    <TextInput placeholder="Search" noHeader={true} type="form-text" onChange={(value)=>{setSearch(value)}} initialValue={search}/>
                  </div>
                  </div>
                  <table style={{overflowY:"auto", flex:"1"}}>
                    <tr className={styles.ordersRecentBookingsTableHeader}>
                      <th className={styles.productsResultHeaderItem}>Claimant Name</th>
                      <th className={styles.productsResultHeaderItem}>PIAB Ref</th>
                      <th className={styles.productsResultHeaderItem}>Assigned Expert</th>
                      <th className={styles.productsResultHeaderItem}>Date</th>
                      <th className={styles.productsResultHeaderItem}>Time</th>
                      <th className={styles.productsResultHeaderItem}>Confirm</th>
                      <th className={styles.productsResultHeaderItem}>Attend</th>
                    </tr>
                    {
                      records.map((record, recordIndex)=>{

                        if(search.length > 0 && !search.toUpperCase().includes(record.claimantName.toUpperCase()) && !record.claimantName.toUpperCase().includes(search.toUpperCase()) && !search.toUpperCase().includes(record.piab.toUpperCase()) && !record.piab.toUpperCase().includes(search.toUpperCase())) return

                        if(recordIndex % 2 != 0){
                          return(
                            <tr className={styles.productsRowEven} onClick={async(event)=>{
                              if(event.target.tagName == 'DIV') return;
                              console.log(record);
                              await setRecordId(record.id);
                              await setPiab(record.piab)
                              await setClaimantName(record.claimantName)
                              await setClaimantPhone(record.claimantPhone)
                              await setClaimantAddress(record.claimantAddress)
                              await setAppointmentDate(record.appointmentDate)
                              await setAppointmentTime(record.appointmentTime)
                              await setClaimantEmail(record.claimantEmail)
                              await setClaimantDOB(record.claimantDOB)
                              await setIncidentDate(record.incidentDate)
                              await setLdta(record.ldta)
                              await setStatus(record.status)
                              await setSolicitorName(record.solicitorName)
                              await setSolicitorEmail(record.solicitorEmail)
                              await setSpecialNoteToSolicitor(record.specialNoteToSolicitor)
                              await setSpecialNoteToDoctor(record.specialNoteToDoctor)
                              await setMedicType(record.medicType)
                              await setSolicitorReference(record.solicitorReference)
                              await setBackupSolicitorEmail(record.backupSolicitorEmail)
                              await setClinicLocation(record.clinicLocation)
                              await setClinics((record.ml_med.clinics == "[]")?[]:record.ml_med.clinics.split('[****SEP****]'))
                              await setClinicAddress(record.clinicAddress)
                              await setExpertEmail(record.expertEmail)
                              await setMedicalExpert(record.medicalExpert)
                              await setAvailableTimes(record.ml_med?.ml_med_dates || [])
                              await setExpertType(record.expertType)
                              await setMedicType(record.medicType)
                              await setMedicId(record.medicId)
                              await setOldDocuments((record.documentURLs || '').split(','))
                              await setRecordIndex(recordIndex)
                              await setEditReferralOpen(true);
                            }}>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.claimantName}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.piab}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.medicalExpert}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.appointmentDate}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.appointmentTime}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>
                                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Confirm Email" onClick={async(event)=>{
                                  const cookies = new Cookies();
                                  const token = await cookies.get('token');

                                  //if(record.confirmed) {
                                    //alert('Please ensure the appointment was not confirmed before sending.');
                                    //return;
                                  //}

                                  const sendReminderRes = await sendConfirmationEmails({token, appointments:[{...record,recordOnly:true}]})

                                  if(sendReminderRes.data.success){
                                    alert("Confirmation emails sent.")
                                  }else{
                                    alert("Error sending emails.")
                                  }
                                }} />
                              </td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>
                                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="DNA" onClick={async(event)=>{
                                  event.preventDefault();
                                  //if(!record.attended && false) {
                                    //alert('Please ensure the appointment was not attended before sending.');
                                    //return;
                                  //}

                                  await setLoading(true);
                                  const cookies = new Cookies();
                                  const token = await cookies.get('token');



                                  const sendReminderRes = await sendReminderEmails({token, appointments:[{...record,recordOnly:true}]})

                                  if(sendReminderRes.data.success){
                                    alert("Reminder emails sent.")
                                  }else{
                                    alert("Error sending emails.")
                                  }

                                  await setLoading(false);
                                }} />
                              </td>
                            </tr>
                          )
                        }

                        return(
                          <tr className={styles.productsRowOdd} onClick={async(event)=>{
                            if(event.target.tagName == 'DIV') return;
                            console.log(record);
                            await setRecordId(record.id);
                            await setPiab(record.piab)
                            await setClaimantName(record.claimantName)
                            await setClaimantPhone(record.claimantPhone)
                            await setClaimantAddress(record.claimantAddress)
                            await setAppointmentDate(record.appointmentDate)
                            await setAppointmentTime(record.appointmentTime)
                            await setClaimantEmail(record.claimantEmail)
                            await setClaimantDOB(record.claimantDOB)
                            await setIncidentDate(record.incidentDate)
                            await setLdta(record.ldta)
                            await setStatus(record.status)
                            await setSolicitorName(record.solicitorName)
                            await setAvailableTimes(record.ml_med?.ml_med_dates || [])
                            await setSolicitorEmail(record.solicitorEmail)
                            await setSpecialNoteToSolicitor(record.specialNoteToSolicitor)
                            await setSpecialNoteToDoctor(record.specialNoteToDoctor)
                            await setMedicType(record.medicType)
                            await setClinics((record.ml_med.clinics == "[]")?[]:record.ml_med.clinics.split('[****SEP****]'))
                            await setSolicitorReference(record.solicitorReference)
                            await setBackupSolicitorEmail(record.backupSolicitorEmail)
                            await setClinicLocation(record.clinicLocation)
                            await setClinicAddress(record.clinicAddress)
                            await setExpertEmail(record.expertEmail)
                            await setMedicalExpert(record.medicalExpert)
                            await setExpertType(record.expertType)
                            await setOldDocuments((record.documentURLs || '').split(','))
                            await setMedicId(record.medicId)
                            await setRecordIndex(recordIndex)
                            await setEditReferralOpen(true);
                          }}>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.claimantName}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.piab}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.medicalExpert}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.appointmentDate}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.appointmentTime}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>
                              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Confirm Email" onClick={async(event)=>{
                                await setLoading(true);
                                const cookies = new Cookies();
                                const token = await cookies.get('token');

                                //if(record.confirmed) {
                                  //alert('Please ensure the appointment was not confirmed before sending.');
                                  //return;
                                //}

                                const sendReminderRes = await sendConfirmationEmails({token, appointments:[{...record,recordOnly:true}]})

                                if(sendReminderRes.data.success){
                                  alert("Confirmation emails sent.")
                                }else{
                                  alert("Error sending emails.")
                                }

                                await setLoading(false);
                              }} />
                            </td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>
                              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="DNA" onClick={async(event)=>{
                                const cookies = new Cookies();
                                const token = await cookies.get('token');

                                //if(!record.attended) {
                                  //alert('Please ensure the appointment was not attended before sending.');
                                  //return;
                                //}

                                const sendReminderRes = await sendReminderEmails({token, appointments:[{...record,recordOnly:true}]})

                                if(sendReminderRes.data.success){
                                  alert("Reminder emails sent.")
                                }else{
                                  alert("Error sending emails.")
                                }
                              }} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
}


}
