
import { serviceProvider } from './serviceProvider'

export const getPortfolios = () => serviceProvider('/');
export const login = (data) => serviceProvider.post('/auth/login', data);
export const enterCode = (data) => serviceProvider.post('/auth/enterCode', data);
export const logout = (token,data) => serviceProvider.delete('/auth/logout/'+token, data);

export const updateStripe = (data) => serviceProvider.post('/admin/updateStripe/'+data.token,data);

export const setSocialToken = (data) => serviceProvider.post('/auth/setSocialToken/'+data.token,data);
export const logoutFromSocial = (data) => serviceProvider.post('/auth/logoutFromSocial/'+data.token,data);

//MedLaw Specific

export const setMedicProfileImageService = (data,token) => serviceProvider.post('/admin/setMedicProfileImage',data,{headers: { Authorization: `Bearer ${token}` }});

export const sendConfirmationEmails = (data) => serviceProvider.post('/admin/sendConfirmationEmails',data,{headers: { Authorization: `Bearer ${data.token}` }});
export const sendReminderEmails = (data) => serviceProvider.post('/admin/sendReminderEmails',data,{headers: { Authorization: `Bearer ${data.token}` }});
export const createDateTime = (data) => serviceProvider.post('/admin/createDateTime',data,{headers: { Authorization: `Bearer ${data.token}` }});
export const deleteDateTime = (data) => serviceProvider.post('/admin/deleteDateTime',data,{headers: { Authorization: `Bearer ${data.token}` }});

export const freeDate = (data) => serviceProvider.post('/admin/freeDate',data,{headers: { Authorization: `Bearer ${data.token}` }});

export const updateTimes = (data) => serviceProvider.post('/admin/updateTimes',data,{headers: { Authorization: `Bearer ${data.token}` }});

export const getAllRecords = (data) => serviceProvider.get('/admin/getAllRecords',{headers: { Authorization: `Bearer ${data.token}` }});
export const updateRecord = (data, token) => serviceProvider.put('/admin/updateRecord',data,{headers: { Authorization: `Bearer ${token}` }});

export const resendEmails = (data, token) => serviceProvider.post('/admin/resendEmails/'+data.formId,data,{headers: { Authorization: `Bearer ${token}` }});

export const createMedicContact = (data, token) => serviceProvider.post('/admin/createMedic',data,{headers: { Authorization: `Bearer ${token}` }});
export const getAllMedics = (data) => serviceProvider.get('/admin/getAllMedics',{headers: { Authorization: `Bearer ${data.token}` }});
export const updateMedic = (data, token) => serviceProvider.put('/admin/updateMedic',data,{headers: { Authorization: `Bearer ${token}` }});
export const updateRumuMedic = (data, token) => serviceProvider.put('/admin/updateRumuMedic',data,{headers: { Authorization: `Bearer ${token}` }});

export const createSolicitorContact = (data, token) => serviceProvider.post('/admin/createSolicitor',data,{headers: { Authorization: `Bearer ${token}` }});
export const getAllSolicitors = (data) => serviceProvider.get('/admin/getAllSolicitors',{headers: { Authorization: `Bearer ${data.token}` }});
export const updateSolicitor = (data, token) => serviceProvider.put('/admin/updateSolicitor',data,{headers: { Authorization: `Bearer ${token}` }});

export const getSolicitorsBySearch = (data) => serviceProvider.get('/admin/getSolicitorsBySearch/'+data.search,{headers: { Authorization: `Bearer ${data.token}` }});
export const getMedicsBySearch = (data) => serviceProvider.get('/admin/getMedicsBySearch/'+data.search,{headers: { Authorization: `Bearer ${data.token}` }});

export const getMedicTypes = (data) => serviceProvider.get('/admin/getMedicTypes',{headers: { Authorization: `Bearer ${data.token}` }});
export const updateMedicTypes = (data) => serviceProvider.post('/admin/updateMedicTypes',data,{headers: { Authorization: `Bearer ${data.token}` }});

//Company
export const getCompany = (data) => serviceProvider.get('/admin/getCompany/'+data.token,data);
export const updateCompany = (data) => serviceProvider.put('/admin/updateCompany/'+data.token,data);
export const uploadCompanyLogo = (data,token) => {
  return serviceProvider.post('/admin/uploadCompanyLogo',data,{
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
    });
}

//Inputs
export const addInput = (data) => serviceProvider.post('/admin/addInput/'+data.token,data);
export const getInputs = (data) => serviceProvider.get('/admin/getInputs/'+data.token,data);
export const updateInput = (data) => serviceProvider.put('/admin/updateInput/'+data.id+'/'+data.token,data);
export const deleteInput = (data) => serviceProvider.delete('/admin/deleteInput/'+data.id+'/'+data.token,data);

//Fitters
export const requestFitter = (data) => serviceProvider.post('/admin/requestFitter/'+data.token,data);
export const getMyFitters = (data) => serviceProvider.get('/admin/getMyFitters/'+data.token,data);
export const sendMessageToFitter = (data) => serviceProvider.post('/admin/sendMessageToFitter/'+data.token,data);

//User details
export const getUserDetailsService = (token,data) => serviceProvider.get('/auth/getUserDetails/'+token,{headers: { Authorization: `Bearer ${token}` }});
export const getAllEmployees = (data) => serviceProvider.get('/admin/getEmployeeDetails/'+data.token,data);
export const getSellerProductDetails = (data) => serviceProvider.get('/admin/getSellerProductDetails/'+data.token,data);
export const createSellerProduct = (data) => serviceProvider.post('/admin/createSellerProduct/'+data.token,data);
export const updateSellerProduct = (data) => serviceProvider.put('/admin/updateSellerProduct/'+data.productId+'/'+data.token,data);
export const deleteColourImage = (data) => serviceProvider.put('/admin/deleteColourImage/'+data.imageId+'/'+data.token,data);
export const uploadColourImage = (data,token) => {
  return serviceProvider.post('/admin/uploadColourImage/'+token,data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}

export const uploadProductDecalImage = (data,token) => {
  return serviceProvider.post('/admin/uploadProductDecalImage/'+data.productId+'/'+token,data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}
//Admin
export const createEmployee = (data) => serviceProvider.get('/admin/createEmployee/'+data.token,data);
export const updateEmployee = (data) => serviceProvider.get('/admin/updateEmployee/'+data.id+'/'+data.token,data);

//Orders
export const createOrder = (data) => serviceProvider.post('/bookings/createBooking/'+data.token,data);
export const getBookings = (data) => serviceProvider.get('/bookings/getBookingsDetails/'+data.token,data);
export const getRecentBookings = (data) => serviceProvider.get('/bookings/getRecentBookingsDetails/'+data.token,data);

//Discounts
export const createSellerDiscount = (data) => serviceProvider.post('/admin/createSellerDiscount/'+data.token,data);
export const deleteSellerDiscount = (data) => serviceProvider.delete('/admin/deleteSellerDiscount/'+data.id+'/'+data.token,data);
export const updateSellerDiscount = (data) => serviceProvider.put('/admin/updateSellerDiscount/'+data.id+'/'+data.token,data);
